import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { getRequest, updateRequest } from "../../api";
import { useSelector, useDispatch } from "react-redux";
import { addToast } from "../../redux/toastSlice";
import { selectCurrentStore } from "../../redux/storeSlice";
import {
  selectCurrentOrganization,
  selectSubscription,
} from "../../redux/organizationSlice";
import CrownIcon from "../../images/CrownIcon.svg";
import SmsAddOnModal from "../../components/Modal/SmsAddOnModal";

const orderTypeList = [
  {
    name: "new_order",
    title: "Order has been created",
    subtitle: "When a new order has been placed",
  },
  {
    name: "shipping_label",
    title: "Label in shipping",
    subtitle: "When leg #1 is shipped",
  },
  {
    name: "in_progress",
    title: "Order in progress",
    subtitle: "When the order is marked as in progress",
  },
  {
    name: "delayed",
    title: "Order delayed",
    subtitle: "When the order is marked as delayed",
  },
  {
    name: "repaired_online",
    title: "Order repaired",
    subtitle: "When the order is marked as repaired",
  },
  {
    name: "shipping_order",
    title: "Order in shipping",
    subtitle: "When leg #2 or leg #3 is shipped",
  },
  {
    name: "delivered",
    title: "Order delivered",
    subtitle: "When the order is marked as delivered",
  },
];

const Notify = ({
  preferences,
  setPreferences,
  setInitialOrderNotificationState,
  setOpenPlanModal,
}) => {
  const [isSmsEnabled, setIsSmsEnabled] = useState(false);
  const [smsAddOnModal, setSmsAddOnModal] = useState(false);
  const [isPaymentMethod, setIsPaymentMethod] = useState(false);

  const currentStore = useSelector(selectCurrentStore);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const organizationSubscription = useSelector(selectSubscription);
  const dispatch = useDispatch();

  const updateBackendPreferences = async (updatedPreferences) => {
    try {
      await updateRequest(`stores/${currentStore?.store?.id}/settings`, {
        preferences: updatedPreferences,
      });
      setInitialOrderNotificationState(updatedPreferences);
    } catch (error) {
      console.error("Error updating preferences:", error);
      dispatch(addToast("Failed to update notification preferences"));
    }
  };

  const handleMasterCheckbox = (checked) => {
    const updatedPreferences = Object.keys(preferences).reduce((acc, key) => {
      acc[key] = {
        email: checked,
        sms: checked && isSmsEnabled ? true : false,
      };
      return acc;
    }, {});

    setPreferences(updatedPreferences);
  };

  const handleOrderTypeCheckbox = (orderType, checked) => {
    setPreferences((prev) => ({
      ...prev,
      [orderType]: {
        email: checked,
        sms: checked && isSmsEnabled ? true : false,
      },
    }));
  };

  const handleNotificationMethodChange = (orderType, selectedMethods) => {
    if (!isSmsEnabled && selectedMethods.includes("sms")) {
      handleSmsSubscription();
      return;
    }

    setPreferences((prev) => ({
      ...prev,
      [orderType]: {
        email: selectedMethods.includes("email"),
        sms: selectedMethods.includes("sms") && isSmsEnabled ? true : false,
      },
    }));
  };

  const handleSmsSubscription = () => {
    if (!isPaymentMethod) {
      dispatch(addToast("Add Payment Method"));
      setOpenPlanModal(true);
      return;
    }
    if (!organizationSubscription) {
      dispatch(
        addToast(
          "Cannot provide SMS service on this Plan. Subscribe to a Paid Plan."
        )
      );
      setOpenPlanModal(true);
      return;
    }
    setSmsAddOnModal(true);
  };

  const isMasterChecked = Object.values(preferences).every((pref) =>
    Object.values(pref).some((value) => value)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await getRequest(
          `stores/${currentStore?.store?.id}`,
          {},
          "order_notification_preferences"
        );

        const defaultPreferences = orderTypeList.reduce((acc, type) => {
          acc[type.name] = { email: false, sms: false };
          return acc;
        }, {});

        results.order_notification_preferences.forEach((pref) => {
          if (defaultPreferences[pref.order_status]) {
            defaultPreferences[pref.order_status] = {
              email: pref.email,
              sms: pref.sms,
            };
          }
        });

        setPreferences(defaultPreferences);
        setInitialOrderNotificationState(defaultPreferences);
      } catch (error) {
        console.error("Error fetching data:", error);
        dispatch(addToast("Failed to fetch notification preferences"));
      }
    };

    fetchData();
  }, [currentStore?.store?.id]);

  useEffect(() => {
    if (currentOrganization) {
      setIsPaymentMethod(!!currentOrganization?.payment_method_id);
    }
  }, [currentOrganization]);

  useEffect(() => {
    if (!organizationSubscription) return;
    const hasSmsAddOn = organizationSubscription.add_on?.some(addOn => addOn.add_on_type === "sms") || false;  
    setIsSmsEnabled(hasSmsAddOn);

    if (!hasSmsAddOn && Object.keys(preferences).length > 0) {
      const updatedPreferences = { ...preferences };

      let shouldUpdate = false;

      Object.keys(preferences).forEach((key) => {
        if (preferences[key].sms) {
          updatedPreferences[key] = { ...preferences[key], sms: false };
          shouldUpdate = true;
        }
      });

      if (shouldUpdate) {
        setPreferences(updatedPreferences);
        updateBackendPreferences(updatedPreferences);
      }
    }
  }, [organizationSubscription, preferences]);

  return (
    <div className="flex flex-col gap-[10px] bg-white px-[40px] py-[32px]">
      <div className="flex flex-col">
        <div className="font-['Montserrat'] text-[18px] font-[600]">
          Notifications
        </div>
        <div className="font-['Questrial'] text-[16px] font-[400]">
          Determine how you want order notifications sent to your customers -
          email or SMS
        </div>
      </div>
      <div className="grid grid-cols-2 border rounded-[8px] border-[#DFDFDF] border-solid">
        <div className="font-['Questrial'] p-[20px] text-[12px] font-normal text-[#272523]">
          <Checkbox
            checked={isMasterChecked}
            onChange={(e) => handleMasterCheckbox(e.target.checked)}
            sx={{ padding: 0, marginRight: "8px" }}
          />
          Notify when
        </div>
        <div className="font-['Questrial'] p-[20px] text-[12px] font-normal text-[#272523]">
          Send through
        </div>
        <div className="col-span-2 w-full border-b" />

        {orderTypeList.map((item) => (
          <React.Fragment key={item.name}>
            <div className="flex flex-row gap-[12px] p-[20px] items-center">
              <Checkbox
                checked={Object.values(preferences[item.name]).some(
                  (value) => value
                )}
                onChange={(e) =>
                  handleOrderTypeCheckbox(item.name, e.target.checked)
                }
                sx={{ padding: 0 }}
              />
              <div className="flex flex-col font-['Questrial'] font-normal">
                <div className="text-[16px] font-normal">{item.title}</div>
                <div className="text-[#939291] text-[12px]">
                  {item.subtitle}
                </div>
              </div>
            </div>
            <div className="p-[20px]">
              <FormControl className="w-full">
                <Select
                  multiple
                  value={Object.entries(preferences[item.name])
                    .filter(([, value]) => value)
                    .map(([key]) => key)}
                  onChange={(e) =>
                    handleNotificationMethodChange(item.name, e.target.value)
                  }
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) return <span>NONE</span>;
                    return selected
                      .map((value) => value.toUpperCase())
                      .join(", ");
                  }}
                  sx={{
                    "& .MuiSelect-select": {
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    },
                  }}
                >
                  {["email", "sms"].map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      className="flex items-center w-full cursor-pointer"
                    >
                      <div className="flex items-center gap-2 flex-grow">
                        <Checkbox checked={preferences[item.name][option]} />
                        <ListItemText primary={option.toUpperCase()} />
                      </div>
                      {option === "sms" && !isSmsEnabled && (
                        <div className="ml-auto px-2 py-1 bg-orange-100 rounded-2xl justify-center items-center inline-flex w-fit">
                          <img
                            alt="logo"
                            src={CrownIcon}
                            className="w-4 h-auto"
                          />
                          <div className="text-center text-orange-500 text-xs font-normal font-['Questrial'] leading-none">
                            Add-On
                          </div>
                        </div>
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </React.Fragment>
        ))}
        <div className="col-span-2 w-full border-b" />
      </div>
      <SmsAddOnModal
        open={smsAddOnModal}
        setOpen={setSmsAddOnModal}
        organization={currentOrganization}
      />
    </div>
  );
};

export default Notify;
