import moment from 'moment';
import { saveAs } from 'file-saver';
import { CUSTOMER_FIELDS, USER_ROLES_ENUM } from "../constants";
import { validateEmail } from ".";
import {
  addressRegex,
  canadaAddressRegex,
  CSV_SPLIT_REGEX,
  phoneNumberFormatRegex,
  usAddressRegex,
} from "./regexPatterns";

export const parseAddress = (address) => {
  const match = address?.match(addressRegex);

  if (match) {
    return {
      line: match[1].trim(),
      city: match[2].trim(),
      state: match[3].trim().split(" ")[0],
      zipcode: match[3].trim().split(" ")[1],
      country: match[4].trim(),
    };
  }

  return {
    line: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
  };
};

export const formatPhoneNumber = (phone) => {
  const cleaned = ("" + phone).replace(/\D/g, "");
  const match = cleaned.match(phoneNumberFormatRegex);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phone;
};

export const isValidAddress = (address) => {
  return Object.values(address).every(
    (field) =>
      field !== null &&
      (typeof field === "string"
        ? field.trim() !== ""
        : field?.value.trim() !== "")
  );
};

export const processCSVData = (csvData) => {
  const rows = csvData.split("\n").map((row) => row.split(CSV_SPLIT_REGEX));
  const headers = rows[0].map((header) => header.trim());

  const expectedHeaders = Object.values(CUSTOMER_FIELDS);

  if (!expectedHeaders.every((header) => headers.includes(header))) {
    throw new Error(
      "Invalid CSV format. Please upload a file with the correct template."
    );
  }

  return rows.slice(1).map((row) => {
    const rowData = {};
    headers.forEach((header, index) => {
      const value = row[index] ? row[index].trim() : "";
      if (value) {
        rowData[header] = value;
      }
    });

    return {
      name: rowData[CUSTOMER_FIELDS.NAME],
      email: rowData[CUSTOMER_FIELDS.EMAIL],
      phone_number: formatPhoneNumber(rowData[CUSTOMER_FIELDS.PHONE_NUMBER]),
      role: USER_ROLES_ENUM.customer,
      last_signup: moment(
        rowData[CUSTOMER_FIELDS.SIGNUP_DATE],
        "MM/DD/YYYY"
      ).format("DD/MM/YYYY"),
      addresses_attributes: [
        {
          ...parseAddress(rowData[CUSTOMER_FIELDS.SHIPPING_ADDRESS]),
          address_type: "shipping",
        },
        {
          ...parseAddress(rowData[CUSTOMER_FIELDS.BILLING_ADDRESS]),
          address_type: "billing",
        },
      ].filter(isValidAddress),
    };
  });
};

export const extractOriginalCSVData = (csvData) => {
  const rows = csvData
    .split("\n")
    .map((row) => row.replace(/\r$/, "").split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/));
  const headers = rows[0].map((header) => header.trim());
  const data = rows
    .slice(1)
    .map((row) => row.map((cell) => cell.replace(/(^"|"$)/g, "").trim()));

  const expectedHeaders = Object.values(CUSTOMER_FIELDS);

  if (!expectedHeaders.every((header) => headers.includes(header))) {
    return {
      errors: [
        "*CSV headers do not match the expected format. Please update and try again.",
      ],
    };
  }

  const globalErrors = new Set();
  data.forEach((row, rowIndex) => {
    const rowObj = row.reduce((acc, cell, index) => {
      acc[headers[index]] = cell;
      return acc;
    }, {});

    const email = rowObj[CUSTOMER_FIELDS.EMAIL];
    if (email && !validateEmail(email)) {
      globalErrors.add({
        error: "Invalid email. Use the format: john.doe@example.com.",
        line: rowIndex + 1,
      });
    }

    const phoneNumber = rowObj[CUSTOMER_FIELDS.PHONE_NUMBER];
    if (phoneNumber && !/^\d{10}$/.test(phoneNumber)) {
      globalErrors.add({
        error: "Invalid phone number. Enter a 10-digit number, e.g., 1234567890.",
        line: rowIndex + 1
      });
    }

    const shippingAddress = rowObj[CUSTOMER_FIELDS.SHIPPING_ADDRESS];
    if (shippingAddress && !validateAddress(shippingAddress)) {
      globalErrors.add({
        error: "Invalid shipping address. Use the format: '123 Main St, NY 12345, USA'.",
        line: rowIndex + 1
      });
    }

    const billingAddress = rowObj[CUSTOMER_FIELDS.BILLING_ADDRESS];
    if (billingAddress && !validateAddress(billingAddress)) {
      globalErrors.add({
        error: "Invalid billing address. Use the format: '123 Main St, NY 12345, USA'.",
        line: rowIndex + 1
      });
    }
  });

  if (globalErrors.size > 0) {
    return { headers, data, errors: Array.from(globalErrors) };
  }
  return { headers, data };
};

export const validateAddress = (address) =>
  usAddressRegex.test(address) || canadaAddressRegex.test(address);

export const downloadCSV = (csvContent, fileName) => {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, fileName);
};

export const downloadPDF = (pdfData, fileName) => {
  const blob = new Blob([pdfData], { type: "application/pdf" });
  saveAs(blob, fileName);
};
