import React from "react";
import { Button, Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CrownIcon from "../../images/CrownIcon.svg";
import { useDispatch } from "react-redux";
import { addToast } from "../../redux/toastSlice";
import { setLoading } from "../../redux/loadingSlice";
import { postRequest } from "../../api";

const SmsAddOnModal = ({ open, setOpen, organization }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddOnSmsSubscription = async () => {
    try {
      dispatch(setLoading(true));
      const response = await postRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stripe/add_ons/enable_sms`,
        { organization_id: organization.id }
      );
      if (response && response.status == 201) {
        setOpen(false);
        dispatch(
          addToast("SMS Add-On Subscribed Successfully. Refresh your page")
        );
      }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: "20px",
          right: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <>
        <div className="flex flex-col gap-2 mx-8 mt-8">
          <div className="ml-1 px-2 py-1 bg-orange-100 rounded-2xl justify-center items-center inline-flex w-fit">
            <img alt="logo" src={CrownIcon} className="w-4 h-auto " />
            <div className="text-center text-orange-500 text-xs font-normal font-['Questrial'] leading-none">
              Add-On
            </div>
          </div>
          <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
            Send messages through SMS
          </p>
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Notify your customer faster by sending messages and order status to
            them through SMS:
          </p>
        </div>

        <hr className="border-[0.5px] border-stone-300 mx-8 mt-4" />

        <div className="flex flex-row justify-between gap-2 mx-8 mt-4">
          <div>Additional Cost</div>
          <div>
            <span className="font-bold">$ 15.00 </span>
            <span>/ month</span>
          </div>
        </div>

        <div className="flex flex-row justify-center items-center gap-2 mx-8 mt-4 mb-8">
          <Button variant="outlinedSecondary" disabled className="w-full">
            Contact Us
          </Button>
          <Button
            variant="containedPrimary"
            className="w-full"
            onClick={handleAddOnSmsSubscription}
          >
            Purchase Now
          </Button>
        </div>
      </>
    </Dialog>
  );
};

export default SmsAddOnModal;
